@import './menu.css';

$breakpoint--tiny: 360px;
$breakpoint--very-small: 480px;
$breakpoint--small: 600px;
$breakpoint--medium: 768px;
$breakpoint--large: 1024px;
$breakpoint--semi-large: 1200px;
$breakpoint--very-large: 1660px;

// $color--first: #606c38;
// $color--second: #283618;
// $color--third: #fefae0;
// $color--fourth: #dda15e;
// $color--fifth: #bc6c25;

//alternative palette
$color--first: #dad7cd;
$color--second: #a3b18a;
$color--third: #588157;
$color--fourth: #3a5a40;
$color--fifth: #344e41;

@mixin tiny {
  @media (min-width: #{$breakpoint--tiny}) {
    @content;
  }
}
@mixin very-small {
  @media (min-width: #{$breakpoint--very-small}) {
    @content;
  }
}
@mixin small {
  @media (min-width: #{$breakpoint--small}) {
    @content;
  }
}
@mixin medium {
  @media (min-width: #{$breakpoint--medium}) {
    @content;
  }
}
@mixin large {
  @media (min-width: #{$breakpoint--large}) {
    @content;
  }
}
@mixin semi-large {
  @media (min-width: #{$breakpoint--semi-large}) {
    @content;
  }
}
@mixin very-large {
  @media (min-width: #{$breakpoint--very-large}) {
    @content;
  }
}

html {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

h2 {
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 15px;

  @include very-large {
    font-size: 55px;
    line-height: 65px;
  }
}

strong {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

h3 {
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
}

h4 {
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 23px;
  text-align: center;
}

h5 {
  font-size: 42px;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: 800;
}

p {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  @include very-large {
    font-size: 20px;
    line-height: 24px;
  }
}

a {
  color: black;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $color--third;
  }
}

.scroll__anchor {
  position: relative;
  top: -120px;
  display: block;
  visibility: hidden;
}

.text__highlighted {
  color: $color--third;
}

header {
  height: 93px;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 20;
  transition: background-color 0.4s;

  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);

  &.opened {
    background-color: $color--third;
  }
}

.header__scrolled {
  background-color: $color--third;
}

body {
  margin: 0;
  padding-bottom: 100px;
  overflow-x: hidden;
}

.scroll__block {
  overflow: hidden;
}

.container {
  width: calc(100% - 10px);
  margin: auto;

  @include tiny {
    width: calc(100% - 20px);
  }

  @include very-small {
    width: 460px;
  }

  @include small {
    width: 580px;
  }

  @include medium {
    width: 740px;
  }

  @include large {
    width: 90%;
  }

  @include very-large() {
    width: 90%;
    max-width: 1640px;
  }
}

.text_bolded {
  font-weight: 800;
}

.navigation {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navigation__background {
  height: 100%;
  width: 100%;
  background-color: white;
}

.navigation__list-container {
  height: 100%;
}

.navigation__list {
  flex-direction: row;
  list-style: none;
  position: static;
  background: none;
  height: 100%;
  width: auto;
  min-width: auto;
  justify-content: flex-end;
  align-items: center;
  padding-left: 40px;
  margin: 0;
  display: none;

  @include large {
    display: flex;
  }
}

.mobile-navigation {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 40%;
  min-width: 280px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-left: 0;
  z-index: 10;
  transform: translateX(100%);
  transition: transform 0.4s;

  &.menu_opened {
    transform: translateX(0);
  }

  @include large {
    display: none;
  }
}

.mobile-navigation__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-left: 0;
}

.mobile-navigation__social-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}

.mobile-navigation__social-item {
  img {
    width: 30px;
    height: auto;
    margin: 0 10px;
  }
}

.navigation__item {
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 800;
  margin-right: 20px;
  margin-bottom: 0;

  a {
    color: #fff;
  }

  @include semi-large {
    margin-right: 35px;
    font-size: 14px;
  }

  @include very-large {
    margin-right: 55px;
  }
}

.navigation__social-icon {
  margin-right: 10px;
  position: relative;
  top: 2px;

  img {
    width: 25px;
    height: auto;

    &:hover {
      opacity: 0.8;
    }
  }
}

.mobile-navigation__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 5%;

  @include large {
  }

  @include very-large {
  }
}

.navigation__hamburger {
  padding: 0;
  margin: 0;
  background: none;
  border: none;

  @include large {
    display: none;
  }
}

.jumbotron__section {
  width: 100vw;
  // max-width: 2379px;
  height: 100vh;
  margin-bottom: 50px;
  position: relative;

  @include very-large {
    margin-bottom: 100px;
  }
}

.jumbotron__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  &-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    // background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGklEQVQYV2NkQAOMUH4DAwMDCDPABODqMAQAKe4BBZTlhh0AAAAASUVORK5CYII=);
    background-color: rgba($color: #000000, $alpha: 0.2);
    background-size: auto, cover;
  }
}

.jumbotron__slider-item {
  object-fit: cover;
  height: 100vh;
  width: 100%;
}

button {
  outline: none;
}

.movie__play-button {
  width: 100px;
  height: 100px;

  a {
    display: block;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @include large {
    width: 195px;
    height: 195px;
  }
}

.jumbotron__arrow-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;

  @include medium {
    bottom: 40px;
  }
}

.jumbotron__arrow-logo {
  width: 50px;
  height: auto;

  @include medium {
    width: 92px;
  }
}

.jumbotron__arrow-link {
  display: block;
  position: relative;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    .jumbotron__arrow-img {
      transform: translate(-50%, -5px);
    }
  }
}

.jumbotron__arrow-name {
  color: white;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin: 0;
  margin-bottom: 0;
  line-height: 28px;

  @include medium {
    font-size: 24px;
    margin-bottom: 15px;
  }

  @include large {
    font-size: 30px;
  }
}

.jumbotron__arrow-text {
  color: white;
  text-align: center;
  margin: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 800;
  line-height: 35px;

  @include medium {
    font-size: 30px;
  }

  @include large {
    font-size: 42px;
  }
}

.jumbotron__arrow-name {
  margin-bottom: 20px;

  @include large {
    margin-bottom: 35px;
  }
}

.jumbotron__arrow-img {
  width: 50px;
  height: auto;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  transition: transform 0.3s;

  @include medium {
    bottom: 0;
  }
}

.film__section {
  margin-bottom: 50px;
}

.film__container {
  background: url('./assets/jumbotron.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: auto;
  height: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include medium {
    height: 400px;
  }

  @include large {
    height: 600px;
    width: 80%;
  }
}

.film__section-title {
  text-align: center;
}

.film__line {
  width: 207px;
  border-top: 11px solid black;
  margin-bottom: 50px;
}

.description__section {
  padding-top: 50px;
  margin-bottom: 100px;

  @include large {
    margin-bottom: 150px;
  }

  @include very-large {
    padding-top: 150px;
    // margin-bottom: 320px;
  }
}

.description__container {
  display: flex;
}

.description__container_top {
  flex-direction: column;
  align-items: center;

  @include large {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include very-large {
    align-items: flex-start;
  }
}

.description__container_bottom {
  flex-direction: column-reverse;
  align-items: center;

  @include large {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include very-large {
    align-items: flex-start;
  }
}

.description__container_bottom-right,
.description__container_top-left {
  text-align: center;
}

.description__container_top-left {
  width: 100%;
  margin-bottom: 50px;

  @include large {
    margin-bottom: 0;
    max-width: 585px;
    width: 35%;
  }

  p {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include very-small {
      width: 80%;
    }

    @include large {
      width: 100%;
    }
  }
}

.description__container_top-right {
  width: 100%;

  img {
    width: 100%;
  }

  @include large {
    width: 58%;
    max-width: 950px;
  }
}

.map__description-container__left {
  max-width: 100%;
  height: 400px;
  width: 90%;
  min-height: 400px;
  margin-bottom: 50px;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
  }

  .google-map-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  @include large {
    width: 49%;
    max-width: 812px;
    margin-bottom: 0;
    min-height: 500px;
  }
}

.map__description-container__right {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

  img {
    margin-left: auto;
    margin-right: auto;
  }

  p {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include very-small {
      width: 80%;
    }

    @include large {
      width: 100%;
    }
  }

  @include large {
    margin-bottom: 0;
    width: 41%;
    max-width: 670px;
  }
}

.map__section {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;

  @include large {
    flex-direction: row;
    align-items: stretch;
  }
}

.map__container {
  width: 100%;
  margin-bottom: 60px;

  @include large {
    width: 55%;
    margin-bottom: 0;
  }

  @include very-large {
    max-width: 782px;
    width: 47%;
  }
}

.map__map-and-legend {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;

  @include large {
    flex-direction: row;
    align-items: stretch;
  }
}

.map {
  max-height: 85vh;
  width: auto;
  max-width: 100%;
}

.map__legend-container {
  margin-bottom: 0;

  @include large {
    margin-bottom: 0;
    margin-left: 60px;
  }

  @include very-large {
    margin-left: 0;
  }
}

.map__legend-list {
  font-family: 'Montserrat';
  list-style: none;
  padding-left: 0;
}

.map__legend-number {
  font-size: 22px;
  font-weight: 700;
  margin-right: 5px;

  @include small {
    font-size: 37px;
  }

  @include very-large {
    font-size: 48px;
  }
}

.map__legend-item {
  font-size: 16px;

  @include small {
    font-size: 20px;
  }

  @include very-large {
    font-size: 24px;
  }
}

.map__legend-line {
  height: 21px;
  width: 86px;
  border: 0;
  border-top: 21px solid $color--third;
  margin-left: 0;
}

.gallery__section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;

  @include large {
    margin-top: 200px;
    margin-bottom: 250px;
  }

  @include very-large {
    margin-top: 250px;
    margin-bottom: 330px;
  }
}

.gallery__container {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  overflow: hidden;
}

.gallery__spacer {
  width: 100%;
  padding-bottom: 75%;
}

.gallery__slider-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.gallery__slider {
  background-color: #aaa;
  width: 100%;
  height: 100%;

  .slick-list {
    height: 100%;
    width: 100%;
  }

  .slick-track {
    height: 100%;
  }

  .slick-slide {
    height: 100%;
    width: 100%;
    display: flex !important;
    flex-direction: row;
    align-items: stretch;
  }

  .slick-next,
  .slick-prev {
    z-index: 3;
    height: 40px;
    width: 40px;

    &:before {
      content: '';
      height: 40px;
      width: 40px;
      display: block;
    }
  }

  .slick-prev {
    left: 8px;

    &:before {
      position: absolute;
      height: 0;
      width: 0;
      border-top: 20px solid rgba(0, 0, 0, 0);
      border-bottom: 20px solid rgba(0, 0, 0, 0);
      border-right: 30px solid #fff;
    }
  }
  .slick-next {
    right: 8px;

    &:before {
      position: absolute;
      height: 0;
      width: 0;
      border-top: 20px solid rgba(0, 0, 0, 0);
      border-bottom: 20px solid rgba(0, 0, 0, 0);
      border-left: 30px solid #fff;
    }
  }
}

.fancybox-container {
  .fancybox-progress {
    background: $color--fourth;
  }
  .fancybox-button {
    color: #fff;
  }
}

.gallery__slider-item {
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;

  &--link {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.image-map__section {
  margin-bottom: 250px;
  display: none;

  @include large {
    display: block;
  }
}

.image-map__title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.image-map__section-title {
  font-size: 42px;
  margin-bottom: 0;
  line-height: 45px;
}

.image-map__section-subtitle {
  margin-bottom: 40px;
  margin-top: 0;
}

.image-map__section-text {
  margin: 0;
}

.image-map__line {
  width: 207px;
  border-top: 11px solid black;
}

.image-map__container {
  width: 100%;
  max-width: 1572px;
  margin: auto;
}

.image-map__wrapper {
  position: relative;
}

.image-map__loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1640px;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cloud-container {
  min-width: 280px;
  padding: 40px 20px;
  background-color: $color--third;
  position: absolute;
  transform: translateY(-100%);
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;
}

.cloud-container__top-container {
  width: 215px;
  height: 42px;
  border: solid black 3px;
  display: flex;
  flex-direction: row;
  margin: auto;
  // margin-bottom: 40px;
}

.cloud-container__bottom-container {
  margin-top: 40px;
}

.cloud-container__number-container {
  width: 100%;
  height: 100%;
}

.cloud-container__number {
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 800;
  line-height: 36px;
  width: 100%;
}

.cloud-container__info {
  text-align: center;
  font-size: 17px;
  font-weight: 800;
  margin: 14px 0;
}

.cloud-container__status-info {
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin: 0;
  margin-top: 55px;
}

.cloud-container__status {
  color: white;
}

.image-map__poziomkowa-map {
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.image-map__item {
  &:hover {
    opacity: 0.7;
  }
}

.main-table {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 16px;
  border-collapse: collapse;
  display: block;

  @include medium {
    display: table;
  }

  @include very-large {
    font-size: 19px;
  }

  &__info {
    text-align: right;
    font-size: 14px;
  }

  &__header {
    &:hover {
      background-color: rgba(88, 129, 87, 0) !important;
    }
  }

  &__mobile-title {
    @include large {
      display: none;
    }
  }

  &__link {
    font-weight: 800;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__wrapper {
    position: relative;
    min-height: 400px;
    padding-bottom: 1px;
    margin-bottom: 50px;

    @include medium {
      margin-bottom: 100px;
    }

    @include very-large {
      margin-bottom: 100px;
    }
  }

  &__loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  a {
    color: $color--third;

    @include medium {
      color: black;
    }
  }

  th {
    font-weight: 800;
    border-bottom: 2px solid black;
    padding-bottom: 15px;
    padding-top: 15px;
    display: block;
    text-align: center;

    @include medium {
      display: table-cell;
    }
  }

  td {
    text-align: center;
    display: block;
    position: relative;
    padding: 5px 0;
    padding-left: 50%;
    padding-right: 5px;
    border-bottom: 1px solid #ddd;

    @include tiny {
      padding-right: 10px;
      text-align: right;
    }

    @include medium {
      padding: 12px 10px;
      display: table-cell;
      border-bottom: none;
      text-align: center;
    }

    &:before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;

      @include tiny {
        left: 10px;
      }
    }

    &:nth-of-type(1):before {
      content: 'Apartament nr.';

      @include medium {
        content: '';
      }
    }
    &:nth-of-type(2):before {
      content: 'Pow. (m2)';

      @include medium {
        content: '';
      }
    }
    &:nth-of-type(3):before {
      content: 'Status';

      @include medium {
        content: '';
      }
    }
    &:nth-of-type(4):before {
      content: 'Cena za m2';

      @include medium {
        content: '';
      }
    }
    &:nth-of-type(5):before {
      content: 'Rzut';

      @include medium {
        content: '';
      }
    }

    &:first-child,
    &:last-child {
      font-weight: 800;
    }
  }

  tr {
    display: block;

    border-top: 1px solid black;

    @include medium {
      border-top: none;
    }

    &:nth-of-type(odd) {
      background-color: #eee;

      @include medium {
        background: none;
      }
    }

    @include medium {
      display: table-row;
    }

    &:hover {
      background-color: rgba(88, 129, 87, 0.1);
    }

    &.main-table__highlighted {
      background-color: $color--third;

      &:hover {
        background-color: rgba(88, 129, 87, 0.8);
      }
    }
  }

  thead {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;

    @include medium {
      display: table-header-group;
      position: static;
    }
  }

  tbody {
    display: block;

    @include medium {
      display: table-row-group;
    }
  }
}

.main-table__first-row {
  td {
    @include medium {
      padding-top: 15px;
    }
  }
}

.garage-table {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 16px;
  border-collapse: collapse;
  display: block;

  @include medium {
    display: table;
  }

  @include very-large {
    font-size: 19px;
  }

  // &__mobile-title {
  //   @include medium {
  //     display: none;
  //   }
  // }

  &__wrapper {
    position: relative;
    max-width: 800px;
    margin: auto;
    padding-bottom: 1px;
    margin-bottom: 100px;

    @include medium {
      margin-bottom: 100px;
    }

    @include very-large {
      margin-bottom: 150px;
    }
  }

  &__header {
    &:hover {
      background-color: rgba(88, 129, 87, 0) !important;
    }
  }

  &__link {
    font-weight: 800;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  a {
    color: $color--third;

    @include medium {
      color: black;
    }
  }

  th {
    font-weight: 800;
    border-bottom: 2px solid black;
    padding-bottom: 15px;
    padding-top: 15px;
    display: block;
    text-align: center;

    @include medium {
      display: table-cell;
    }
  }

  td {
    text-align: center;
    display: block;
    position: relative;
    padding: 5px 0;
    padding-left: 50%;
    padding-right: 5px;
    border-bottom: 1px solid #ddd;

    @include tiny {
      padding-right: 10px;
      text-align: right;
    }

    @include medium {
      padding: 12px 10px;
      display: table-cell;
      border-bottom: none;
      text-align: center;
    }

    &:before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;

      @include tiny {
        left: 10px;
      }
    }

    &:nth-of-type(1):before {
      content: 'Miejsce nr.';

      @include medium {
        content: '';
      }
    }
    &:nth-of-type(2):before {
      content: 'Status';

      @include medium {
        content: '';
      }
    }
    &:nth-of-type(3):before {
      content: 'Cena';

      @include medium {
        content: '';
      }
    }
    &:nth-of-type(4):before {
      content: 'Rzut';

      @include medium {
        content: '';
      }
    }

    &:first-child,
    &:last-child {
      font-weight: 800;
    }
  }

  tr {
    display: block;

    border-top: 1px solid black;

    @include medium {
      border-top: none;
    }

    &:nth-of-type(odd) {
      background-color: #eee;

      @include medium {
        background: none;
      }
    }

    @include medium {
      display: table-row;
    }

    &:hover {
      background-color: rgba(88, 129, 87, 0.1);
    }

    &.main-table__highlighted {
      background-color: $color--third;

      &:hover {
        background-color: rgba(88, 129, 87, 0.8);
      }
    }
  }

  thead {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;

    @include medium {
      display: table-header-group;
      position: static;
    }
  }

  tbody {
    display: block;

    @include medium {
      display: table-row-group;
    }
  }
}

.info__section {
  display: flex;
  flex-direction: column;
}

.info__ext-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.info__box {
  height: 230px;
  width: 230px;
  max-width: 100%;
  background-color: $color--third;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  border-radius: 10px;

  &:hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
}

.info__title {
  margin: auto;
  font-size: 21px;
}

.info__container {
  width: 100%;

  @include small {
    width: 50%;
  }

  @include large {
    width: 33%;
  }
}

.info__text {
  text-align: center;
  margin-bottom: 55px;
  max-width: 350px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.contact__section {
  margin-top: 150px;

  @include very-large {
    margin-top: 282px;
  }
}

.contact__title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;

  @include very-small {
    margin-bottom: 60px;
  }

  @include large {
    margin-bottom: 95px;
  }
}

.contact__section-title {
  font-size: 42px;
}

.contact__line {
  width: 207px;
  border-top: 11px solid black;
}

.contact__container {
  display: flex;
  flex-direction: column-reverse;
  // justify-content: space-between; ustawienie gdy dodany formularz kontaktowy

  @include large {
    display: flex;
    flex-direction: row;
    // justify-content: space-between; ustawienie gdy dodany formularz kontaktowy
    justify-content: center;
  }
}

.contact__form-container {
  max-width: 750px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include large {
    width: 60%;
  }

  @include very-large {
    width: 100%;
  }
}

.contact__info {
  margin-bottom: 60px;

  @include large {
    margin-bottom: 0;
  }
}

.contact__form-input {
  height: 58px;
  width: 100%;
  border: none;
  margin-bottom: 23px;
  background-color: rgba(0, 0, 0, 0.05);
  padding-top: 1px;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 23px;
  font-family: 'Montserrat';
  font-size: 19px;
  color: #9b9b9b;

  &.error {
    background-color: rgba($color: #b30808, $alpha: 0.3);
    color: #b30808;

    &::placeholder {
      color: #b30808;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #b30808;
    }

    &::-ms-input-placeholder {
      color: #b30808;
    }
  }
}

.contact__form-textarea {
  height: 390px;
  width: 100%;
  border: none;
  margin-bottom: 23px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 27px 30px;
  font-family: 'Montserrat';
  font-size: 19px;
  color: #9b9b9b;
  resize: none;

  &.error {
    background-color: rgba($color: #b30808, $alpha: 0.3);
    color: #b30808;

    &::placeholder {
      color: #b30808;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #b30808;
    }

    &::-ms-input-placeholder {
      color: #b30808;
    }
  }
}

.checkbox_container {
  padding-left: 10px;

  a {
    color: $color--third;
  }

  .styled_checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Montserrat';
    color: #78787a;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .styled_checkbox input {
    position: absolute;
    opacity: 1;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    border-radius: 2px;
    height: 14px;
    width: 14px;
    box-shadow: 0px 0px 0px 1px #72e9ed;
    border: solid white 1px;
    background-color: #ffffff;
  }

  .styled_checkbox input:checked ~ .checkmark {
    background-color: $color--third;
  }

  &.error {
    .styled_checkbox {
      color: #b30808;
    }

    .checkmark {
      border: solid #b30808 1px;
      background-color: rgba($color: #b30808, $alpha: 0.3);
    }
  }
}

.contact__form-button {
  height: 58px;
  width: 100%;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Montserrat';
  font-size: 19px;
  background-color: $color--third;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.contact__info-title {
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 20px;

  @include very-small {
    font-size: 32px;
    margin-bottom: 50px;
  }

  @include large {
    margin-top: 130px;
  }
}

.contact__phone,
.contact__mail {
  font-size: 18px;
  margin: 0;
  line-height: 39px;
  text-align: center;

  @include very-small {
    font-size: 32px;
  }

  @include large {
    font-size: 26px;
  }

  @include very-large {
    font-size: 32px;
  }
}

.contact__social-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;

  @include very-small {
    margin-top: 88px;
  }
}

.contact__social-icon {
  height: 30px;
  width: 30px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 19px;

  @include very-small {
    height: 42px;
    width: 42px;
  }

  &:hover {
    opacity: 0.5;
  }
}

.contact__facebook {
  background-image: url('./assets/facebook.png');
}

.contact__instagram {
  background-image: url('./assets/instagram.png');
}

.line-spacer {
  height: 21px;
  width: 86px;
  display: block;
  margin: 20px auto;
  background-color: $color--third;
}

.pano {
  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;

    h2 {
      margin-bottom: 0;
    }

    @include large {
      margin-top: 100px;
      margin-bottom: 200px;
    }

    @include very-large {
      margin-top: 100px;
      margin-bottom: 250px;
    }
  }

  &__wraper {
    position: relative;
    padding-bottom: 60%;
    max-width: 1200px;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;

    @include large {
      padding-bottom: 50%;
    }

    @include very-large {
      padding-bottom: 40%;
    }
  }

  &__iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.link-buttons__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;

  @include very-small {
    margin-bottom: 100px;
  }

  @include large {
    margin-bottom: 150px;
  }
}

.link-button {
  background-color: rgba(88, 129, 87, 0.9);
  padding: 20px 10px;
  border-radius: 10px;
  color: #000;
  font-weight: 700;
  font-family: 'Montserrat';
  transition: background-color 0.3s;
  text-align: center;

  @include very-small {
    padding: 20px 20px;
  }

  @include large {
    padding: 20px 40px;
    font-size: 20px;
  }

  &:hover {
    background-color: $color--third;
    color: #000;
    text-decoration: underline;
  }
}
